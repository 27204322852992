import React from 'react';
import VideoPlayer from './VideoPlayer';  // Adjust import path as needed

const VideoInfo = ({ name, videoUrl }) => (
  <div
    style={{
      fontFamily: 'Arial, sans-serif',
      // Remove or increase maxWidth to allow a wider video container
      maxWidth: '800px',
      margin: '0 auto', // Centers the container horizontally
    }}
  >
    {/* <h3 style={{ marginBottom: '10px', textAlign: 'center' }}>
      Video: {name}
    </h3> */}
    <VideoPlayer
      url={videoUrl}
      width="100%"
      // Increase the height for a bigger display
      height="450px"
    />
  </div>
);

export default VideoInfo;
