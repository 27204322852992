import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import styles from './HomePage.module.css';
import { getFirestore, doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { signOut } from "firebase/auth";
import { auth } from './firebaseConfig';
import Dialog from '@mui/material/Dialog';
import VideoInfo from './components/VideoInfo';
import ImageInfo from './components/ImageInfo';
import Image from './components/Image';
import FreeContent from './FreeContent'; // Import the FreeContent component

// Google Spreadsheet URLs (for Call Options and Put Options TSV data)
const GOOGLE_SHEET_URL_CALLS = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vR90qpwRf_F7eRYjbYA3PKvH1XuLlZZMPxRr3q4K9ktxhcMSSJRkJQTCmZ-9m93YpAItpMtbR4aY0M8/pub?output=tsv';

// Fetch TSV data from Google Sheets
const fetchTSVData = async (url) => {
  try {
    const response = await fetch(url);
    const tsvData = await response.text();
    return tsvData;
  } catch (error) {
    console.error('Error fetching TSV data:', error);
    return '';
  }
};

// Parse the raw TSV data (converting the free flag to a boolean)
const parseTSV = (tsv) => {
  const lines = tsv.trim().split('\n');
  const headers = lines[0].split('\t');
  return lines.slice(1).map((line) => line.split('\t').reduce((acc, value, i) => {
    acc[headers[i]] = value.trim();
    return acc;
  }, {})).map(lesson => ({
    ...lesson,
    free: lesson.free === 'true' || lesson.free === '1'  // Ensure free is a boolean
  }));
};

// Fetch lessons from multiple sections
const fetchAllLessonsData = async () => {
  const callsData = await fetchTSVData(GOOGLE_SHEET_URL_CALLS);

  return [
    { section: 'Calls', lessons: parseTSV(callsData) },
    // { section: 'Puts', lessons: parseTSV(putsData) },
  ];
};

const LessonCard = React.memo(({ lesson, onClick }) => {
  return (
    <div className={styles.card} onClick={() => onClick(lesson)}>
      <div className={styles.cardContent}>
        <h3 className={styles.cardTitle}>{lesson.title}</h3>
        
        {/* Media Section */}
        <div className={styles.mediaContainer}>
          {lesson.imageUrl && (
            <div className={styles.mediaWrapper}>
              <Image src={lesson.imageUrl} />
            </div>
          )}
          {lesson.videoUrl && (
            <div className={styles.mediaWrapper}>
              <VideoInfo videoUrl={lesson.videoUrl} />
            </div>
          )}
        </div>

        <p className={styles.cardCaption}>{lesson.caption}</p>
        <p className={styles.cardDescription}>{lesson.description}</p>
        <div className={styles.cardTags}>
          {lesson.tags?.split(',').map((tag) => (
            <span key={tag} className={styles.tag}>{tag.trim()}</span>
          ))}
        </div>
        {/* Progress bar section commented out
        <div className={styles.progressBar}>
          <div className={styles.progressFill} style={{ width: `${lesson.percentComplete}%` }}></div>
        </div>
        <p>Progress: {lesson.percentComplete}%</p>
        */}
        <div className={styles.cardLink}>
          {lesson.free ? (lesson.percentComplete === 100 ? "Review" : "Continue Learning") : "Unlock Lesson"}
        </div>
      </div>
      {!lesson.free && <div className={styles.lockedOverlay}>Premium Content</div>}
    </div>
  );
});

function HomePage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [sectionsData, setSectionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasPurchased, setHasPurchased] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [LoadedComponent, setLoadedComponent] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching lessons data...");
        let sections = await fetchAllLessonsData();
        console.log("Lessons data fetched:", sections);

        if (user && user.uid) {
          console.log("User is logged in, fetching user data...");
          const db = getFirestore();
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            setHasPurchased(userDoc.data().hasPurchased || false);

            // Fetch user's progress
            const progressRef = collection(db, 'users', user.uid, 'progress');
            const progressSnapshot = await getDocs(progressRef);
            const progressData = {};

            progressSnapshot.forEach(doc => {
              progressData[doc.id] = doc.data();
            });

            // Update lessons with user's progress data
            sections = sections.map(section => ({
              ...section,
              lessons: section.lessons.map(lesson => ({
                ...lesson,
                percentComplete: progressData[lesson.id]?.percentComplete || 0
              }))
            }));
          }
        }

        setSectionsData(sections);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user]);

  const handleSignOut = () => {
    signOut(auth).then(() => {
      localStorage.removeItem('userAuthenticated');
      navigate('/');
    }).catch((error) => {
      console.error("Sign out error", error);
    });
  };
  
  const cleanLessonObject = (lesson) => {
    const cleanedLesson = {};
    Object.keys(lesson).forEach((key) => {
      const cleanedKey = key.replace(/\r/g, '');  // Clean the key (e.g., from 'file\r' to 'file')
      const cleanedValue = typeof lesson[key] === 'string' ? lesson[key].replace(/\r/g, '') : lesson[key];  // Clean the value if it's a string
      cleanedLesson[cleanedKey] = cleanedValue;  // Add the cleaned key-value pair to the new object
    });
    return cleanedLesson;
  };

  const handleCardClick = async (lesson) => {
    const cleanedLesson = cleanLessonObject(lesson);
    
    // Check if the lesson is free or if the user has purchased access
    if (cleanedLesson.free || hasPurchased) {
      setSelectedLesson(cleanedLesson);

      if (cleanedLesson.file) {
        const componentPath = `./${cleanedLesson.file}.jsx`;
        try {
          const component = await import(`${componentPath}`);
          setLoadedComponent(() => component.default);
          setDialogOpen(true);
        } catch (error) {
          console.error("Error loading component from path:", componentPath, error);
          alert(`Error loading component: ${error.message}`);
        }
      } else {
        alert("No valid file path provided.");
      }
    } else {
      alert("Email to office@21ifm.com for a demo and to discuss your goals. ");
    }
  };
  
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedLesson(null);
    setLoadedComponent(null);
  };

  const renderSection = (section, index) => (
    <div key={index}>
      {index > 0 && (
        <div className={styles.customSection}>
          <h2>Intermediate Section Heading</h2>
          <p>Some custom content or component can go here.</p>
        </div>
      )}
      <h2>{section.section === 'Calls' ? 'Master Mathematics with Interactive Tools' : 'Put Options Lessons'}</h2>
      <div className={styles.cardGrid}>
        {section.lessons.map((lesson) => (
          <LessonCard 
            key={lesson.id} 
            lesson={{ ...lesson, free: lesson.free || hasPurchased }} 
            onClick={handleCardClick}
          />
        ))}
      </div>
    </div>
  );

  if (isLoading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  if (sectionsData.length === 0) {
    return <div className={styles.noLessons}>No lessons available at the moment.</div>;
  }


  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>Calculus 2 Interactive Explainers</h1>
        <p>
          Designed for top performance in competitive exams.
        </p>

      </header>
  

  
      <main>
        <section className={styles.userSection}>
          {user ? (
            <div>
              <p>Welcome back, {user.email}!</p>
              <p>
                {hasPurchased 
                  ? "You have premium access." 
                  : "Upgrade to premium for full access. You can explore free content in the meantime."}
              </p>
              {!hasPurchased && (
                <Link to="/purchase" className={styles.cardLink}>Upgrade to Premium</Link>
              )}
              <button onClick={handleSignOut} className={styles.signOutButton}>Sign Out</button>
            </div>
          ) : (
            <div>
              <p>Sign in to access your personalized dashboard, explore free content, and unlock premium features.</p>
              <Link to="/signin" className={styles.cardLink}>Sign In</Link>
            </div>
          )}
        </section>
        
        {sectionsData.map((section, index) => renderSection(section, index))}
  
        {!hasPurchased && (
          <section className={styles.userSection}>
            <h2>Premium Content</h2>
            <p>Unlock advanced strategies and personalized advice with our premium subscription.</p>
            <Link to="/purchase" className={styles.cardLink}>Upgrade to Premium</Link>
          </section>
        )}
      </main>
  
      {/* Full-Screen Dialog */}
      <Dialog fullScreen open={dialogOpen} onClose={handleCloseDialog}>
        <div className={styles.dialogContent}>
          <button className={styles.closeButton} onClick={handleCloseDialog}>Close</button>
          {LoadedComponent && <LoadedComponent />}
        </div>
      </Dialog>
    </div>
  );
}

export default HomePage;

