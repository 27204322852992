import React, { useState } from 'react';
import VideoInfo from './VideoInfo';
import ImageInfo from './ImageInfo';

const Slider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const currentSlide = slides[currentIndex];

  return (
    <div style={styles.sliderContainer}>
      <h2 style={styles.sliderTitle}>Introduction</h2>
      <div style={styles.slideWrapper}>
        {currentSlide.type === 'image' ? (
          <ImageInfo imageUrl={currentSlide.url} />
        ) : (
          <VideoInfo videoUrl={currentSlide.url} />
        )}
      </div>
      <div style={styles.navContainer}>
        <button style={styles.navButton} onClick={goToPrevious}>
          &lt; Prev
        </button>
        <p style={styles.indicator}>
          {currentIndex + 1} / {slides.length}
        </p>
        <button style={styles.navButton} onClick={goToNext}>
          Next &gt;
        </button>
      </div>
    </div>
  );
};

const styles = {
  sliderContainer: {
    width: '100%',
    maxWidth: '1200px', // Increased maxWidth
    margin: '0 auto',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    boxSizing: 'border-box',
    padding: '0 10px',
  },
  sliderTitle: {
    margin: '20px 0',
  },
  slideWrapper: {
    marginBottom: '10px',
  },
  navContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  navButton: {
    backgroundColor: '#2196F3',
    border: 'none',
    color: '#fff',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  indicator: {
    margin: '0',
    fontWeight: 'bold',
    color: '#555',
  },
};

export default Slider;
