// import React from 'react';
// import { Link } from 'react-router-dom';
// import './FreeContent.css';
// import Image from './components/Image';

// const freeLessons = [
//   {
//     id: 1,
//     title: "Rate of Change in Baseball",
//     description: "Learn rate of change through a baseball example where two runners move at different velocities on a 90-foot diamond field.",
//     details: {
//       duration: "20 minutes",
//       difficulty: "Beginner"
//     },
//     imageUrl: "https://storage.googleapis.com/chapters-ifm/explainers/2025/baseball_diamond.png",
//     tags: ["Calculus", "Real-world Application", "Interactive"]
//   }
// ];

// function FreeContent() {
//   return (
//     <div className="free-content-container">
//       <h1>Free Example</h1>

//       {/* NEW EXPLANATION PARAGRAPH */}
//       <p className="intro-text">
//       We start with real-world problems, cutting through theory. Our interactive, audio and video supported lessons
//       use practical examples to help you master concepts, build problem-solving skills, and succeed in exams.
//       </p>

//       <div className="lesson-grid">
//         {freeLessons.map((lesson) => (
//           <div key={lesson.id} className="lesson-card">
//             <div className="card-media">
//               {lesson.imageUrl && <Image src={lesson.imageUrl} alt={lesson.title} />}
//             </div>
//             <div className="card-content">
//               <h2>{lesson.title}</h2>
//               <p className="description">{lesson.description}</p>
//               <div className="lesson-details">
//                 <span className="duration">{lesson.details.duration}</span>
//                 <span className="difficulty">{lesson.details.difficulty}</span>
//               </div>
//               <div className="tags">
//                 {lesson.tags.map((tag) => (
//                   <span key={tag} className="tag">{tag}</span>
//                 ))}
//               </div>
//               <Link to={`/free-lesson/${lesson.id}`} className="start-button">
//                 Begin Interactive Lesson
//               </Link>
//             </div>
//           </div>
//         ))}
//       </div>

//     </div>
//   );
// }

// export default FreeContent;


import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext'; // Ensure AuthContext is set up
import './FreeContent.css';
import Image from './components/Image';

const auth = getAuth();
const db = getFirestore();
const provider = new GoogleAuthProvider();

const freeLessons = [
  {
    id: 1,
    title: "Rate of Change in Baseball",
    description: "Learn rate of change through a baseball example where two runners move at different velocities on a 90-foot diamond field.",
    details: {
      duration: "20 minutes",
      difficulty: "Beginner"
    },
    imageUrl: "https://storage.googleapis.com/chapters-ifm/explainers/2025/baseball_diamond.png",
    tags: ["Calculus", "Real-world Application", "Interactive"]
  }
];

function FreeContent() {
  const { user } = useAuth();
  const [authUser, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setAuthUser(user);
      checkAndStoreUser(user);
    }
    setLoading(false);
  }, [user]);

  const handleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      setAuthUser(result.user);
      checkAndStoreUser(result.user);
    } catch (error) {
      console.error("Google Sign-In Error:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setAuthUser(null);
    } catch (error) {
      console.error("Sign-Out Error:", error);
    }
  };

  const checkAndStoreUser = async (user) => {
    if (!user) return;

    // ✅ Store free users under "freeUsers" collection directly (not inside users/{userId})
    const userRef = doc(db, 'freeUsers', user.uid);

    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      await setDoc(userRef, {
        email: user.email,
        name: user.displayName || "",
        joined: new Date(),
      });
    }
  };

  return (
    <div className="free-content-container">
      <h1>Free Example</h1>

      {/* Google Sign-In Button */}
      <div className="auth-section">
        {authUser ? (
          <div className="user-info">
            <p>Signed in as: <strong>{authUser.email}</strong></p>
            <button onClick={handleSignOut} className="auth-button sign-out">
              Sign Out
            </button>
          </div>
        ) : (
          <button onClick={handleSignIn} className="auth-button sign-in">
            Sign in with Google to Access Free Interactive Lessons
          </button>
        )}
      </div>

      {/* Always Show Lesson Images & Descriptions */}
      <div className="lesson-grid">
        {freeLessons.map((lesson) => (
          <div key={lesson.id} className="lesson-card">
            <div className="card-media">
              {lesson.imageUrl && <Image src={lesson.imageUrl} alt={lesson.title} />}
            </div>
            <div className="card-content">
              <h2>{lesson.title}</h2>
              <p className="description">{lesson.description}</p>
              <div className="lesson-details">
                <span className="duration">{lesson.details.duration}</span>
                <span className="difficulty">{lesson.details.difficulty}</span>
              </div>
              <div className="tags">
                {lesson.tags.map((tag) => (
                  <span key={tag} className="tag">{tag}</span>
                ))}
              </div>

              {/* Restrict Interactive Access to Logged-in Users */}
              {authUser ? (
                <Link to={`/free-lesson/${lesson.id}`} className="start-button">
                  Begin Interactive Lesson
                </Link>
              ) : (
                <p className="login-message">Sign in to start this interactive lesson.</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FreeContent;
