import React from "react";
import styles from "./DevelopedBy.module.css"; // Ensure proper styling

const DevelopedBy = () => {
  return (
    <div className={styles.container}>
      {/* Header */}
      <div className={styles.header}>
        <h1>Pavan Mirla</h1>
        <p>✔ AI Consultant for Quant Hedge Funds in USA & Canada</p>
      </div>

      <hr />

      {/* Why I Created This */}
      <div className={styles.section}>
        <h2>🎯 Why This Platform?</h2>
        <p>
          Many <strong>struggle with math</strong> but dream of becoming 
          <strong> engineers, doctors, or scientists</strong>.  
          My <strong>daughter is homeschooled & on the autism spectrum</strong>, 
          and I wanted to create an <strong>interactive, fear-free</strong> way 
          for her to <strong>master calculus</strong>.
        </p>
        <p className={styles.highlight}>
          🔥 Every student deserves the <strong>right tools & approach</strong> to succeed in math.
        </p>
      </div>

      <hr />

      {/* What Makes This Unique */}
      <div className={styles.section}>
        <h2>🚀 What’s Different?</h2>
        <ul>
          <li>✔ <strong>Intuition over memorization</strong></li>
          <li>✔ <strong>Real-world examples & interactive visuals</strong></li>
          <li>✔ <strong>Student-driven learning & progress tracking</strong></li>
        </ul>
        <p className={styles.highlight}>
          📌 <strong>Math is the key to innovation & problem-solving.</strong>
        </p>
      </div>

      <hr />

      {/* Contact Info */}
      <div className={styles.contactSection}>
        <h2>📬 Get in Touch</h2>
        <p>📩 <strong>Email:</strong> <a href="mailto:contactus@21ifm.com">contactus@21ifm.com</a></p>
        <p>📞 <strong>Phone:</strong> <a href="tel:2369898450">236-989-8450</a></p>
        <p>🔗 <strong>LinkedIn:</strong> <a href="https://www.linkedin.com/in/pavanmirla/" target="_blank" rel="noopener noreferrer">Pavan Mirla</a></p>
      </div>
    </div>
  );
};

export default DevelopedBy;
