import React, { useState } from "react";
import { db } from "./firebaseConfig"; // Import Firebase
import { collection, addDoc } from "firebase/firestore";

const FreeChapterRequest = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const handleRequest = async () => {
    // Basic email validation
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setStatus("❌ Please enter a valid email address.");
      return;
    }

    setStatus("⏳ Saving your request...");

    try {
      // Save email to Firebase
      await addDoc(collection(db, "free_chapter_requests"), {
        email: email,
        timestamp: new Date(),
      });

      setStatus("✅ Request saved! Opening email...");

      // Open default mail client with pre-filled email
      window.location.href = `mailto:office@perceptron.solutions?subject=Request Free Chapter&body=Hello,%0D%0A%0D%0AI would like to request a free chapter. My email is: ${email}%0D%0A%0D%0AThank you.`;

      setEmail(""); // Reset input
    } catch (error) {
      console.error("Error saving request:", error);
      setStatus("❌ Something went wrong. Please try again.");
    }
  };

  return (
    <div className="p-6 max-w-md mx-auto bg-white shadow-md rounded-lg border text-center">
      <h2 className="text-xl font-bold mb-3">📘 Request a Free Chapter</h2>
      <p className="text-gray-600 mb-4">Enter your email to receive a free chapter demo.</p>

      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="p-2 border rounded-md mb-3 w-full"
        required
      />
      <button
        onClick={handleRequest}
        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition w-full"
      >
        Get Free Chapter
      </button>

      {status && <p className="mt-3 text-sm font-semibold">{status}</p>}
    </div>
  );
};

export default FreeChapterRequest;
